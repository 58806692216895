export const formStyles = `

.hs-error-msgs .inputs-list {
  height: 1rem;
}

.hs_error_rollup {
  display: none;
}


.hs-form .hs_i_have_read_and_agree_to_privacy_policy {
  min-height: 5rem;
  margin-bottom: -1rem;

}

.hs-form .hs-button {
  height: 3.5rem;
  color: #ffffff; /* $core-white */
  font-size: 1.25rem;
  line-height: 2rem;
  border: none;
  border-radius: 4rem;
  text-align: center;
  box-shadow: none;
  position: relative;
  width: 100%;
  padding: 0.75rem 0;
  margin-top: 0.5rem;
  transition: background-color 300ms ease-in-out, box-shadow 300ms ease-in-out;
  cursor: pointer;
}

.hs-form .hs-button:focus {
  font-size: 1.25rem !important;
}

.hs-form .hs-button:hover  {
  font-size: 1.25rem !important;
  background: linear-gradient(67.33deg, #0e4ed7 -19.99%, #f94743 98.54%);
  box-shadow: 0.5rem 0.5rem 1.5rem rgba(65, 70, 176, 0.86); /* Apply the box shadow */
  transition: background 300ms ease-in-out, box-shadow 300ms ease-in-out;
}


@media screen and (min-width: 576px) {
  .hs-form .hs-button {
    padding: 0.75rem 3rem;
    min-width: 17rem;
    width: auto;
  }
}

@media screen and (min-width: 1200px) {
  .hs-form .hs-button {
    padding: 0.75rem 1.5rem;
  }
}

.hs-form .hs-button-custom-class-filled:after {
  content: '';
  box-shadow: 0.5rem 0.5rem 1.5rem rgba(65, 70, 176, 0.86);
  opacity: 0;
  transition: opacity 300ms ease-in-out;
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 4rem;
  z-index: 0;
}

.hs-form .hs-button-custom-class-filled:hover:after {
  opacity: 1;
}

.hs-form .inputs-list {
  display: flex;
  padding-left: 0;
  list-style-type: none;
}

.hs-form .hs-form-booleancheckbox {
  display: flex;
  align-items: center;
  position: relative;
}

.hs-form .hs-form-booleancheckbox-display {
  display: flex;
  width: 100%;
  margin-bottom: 0;
  padding-left: 0;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
}

@media screen and (max-width: 576px) {
  .hs-form .hs_i_have_read_and_agree_to_privacy_policy { 
    min-height: 7rem;
  }
}


.hs-form .hs-form-booleancheckbox input[type='checkbox'] {
  accent-color: #000;
  min-height: 1.5rem;
  min-width: 1.5rem;
  padding-right: 1rem;
  padding-left: 1rem;
  flex: 0 auto;
  border-style: solid;
  border-width: 1px;
  border-color: #d8dfe4; /* $grey */
  border-radius: 0.25rem;
  background-color: transparent;
  font-family: var(--font-second);
  color: #000000; /* $core-black */
  font-size: 1rem;
  text-align: left;
}

.hs-form .hs-form-booleancheckbox span {
  margin-left: 0.75rem;
  font-family: var(--font-second);
  font-style: normal;
  font-weight: 300;
  font-size: 1.125rem;
  line-height: 1.688rem;
  color: #ffffff; /* $core-white */
}

.hs-form .hs-form-booleancheckbox span a {
  color: #ffffff; /* $core-white */
}

.hs-form .hs-form-required {
  display: none;
}

.hs-form .hs-error-msgs {
  width: 100%;
  font-size: 0.75rem;
  margin: 0;
}

.hs-form .hs-error-msg {
  color: #f94743; /* $accent */
}

.hs-form .hs-fieldtype-textarea {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  padding: 1.5rem 0 0 0;
  margin-bottom: 0;
  overflow: hidden;
  min-height: 5rem;
}

.hs-form .hs-fieldtype-textarea label span {
  font-size: 1rem;
  line-height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  flex: 0 0 100%;
  color: #d8dfe4; /* $grey */
  transition: top 200ms ease-in-out, font-size 200ms ease-in-out, line-height 200ms ease-in-out;
  cursor: text;
}

.hs-form .hs-fieldtype-textarea .input {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 1.3125rem;
  flex: 0 0 100%;
  border-bottom: 0.0625rem solid #d8dfe4; /* $grey */
  margin-top: 0.5rem;
  height: auto;
  padding-left: 0.938rem;
}

.hs-form .hs-fieldtype-textarea .hs-input {
  position: relative;
  background: none;
  border: none;
  line-height: 2rem;
  padding: 0;
  flex: 1 1;
  color: #ffffff; /* $core-white */
  border-radius: 0;
}

.hs-form .hs-fieldtype-textarea .hs-input:focus {
  outline: none;
}

.hs-form .hs-fieldtype-text {
  border: 0;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 0;
  padding: 1.5rem 0 0 0;
  min-height: 6.5rem;
}

.hs-form .hs-fieldtype-text .input {
  margin-top: 0.5rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 0.5rem;
  flex: 0 0 100%;
  height: 2rem;
  border-bottom: 0.0625rem solid #d8dfe4; /* $grey */
}

.hs-form .hs-fieldtype-text .hs-input:-webkit-autofill {
  padding-top: 1rem;
  height: 2rem;
}

.hs-form .hs-fieldtype-text .hs-input {
  position: relative;
  background: none;
  border: none;
  line-height: 2rem;
  padding: 1rem 0 0 0;
  flex: 1 1;
  color: #ffffff !important; /* $core-white */
  border-radius: 0;
  border: 0;
  height: 2rem;
}

.hs-form .hs-fieldtype-text .hs-input:-webkit-autofill,
.hs-form .hs-fieldtype-text .hs-input:-webkit-autofill:hover,
.hs-form .hs-fieldtype-text .hs-input:-webkit-autofill:focus,
.hs-form .hs-fieldtype-text .hs-input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 50px #000000 inset !important; /* $core-black */
  -webkit-text-fill-color: #ffffff !important; /* $core-white */
  border-bottom: 0.0625rem solid #d8dfe4 !important; /* $grey */
}

.hs-form .hs-fieldtype-text .hs-input:focus {
  outline: none;
}

.hs-form .hs-fieldtype-text .hs-input:focus + label span {
  top: -0.875rem;
  font-size: 0.75rem;
  line-height: 0.875rem;
}

.hs-form .hs-fieldtype-text label span {
  font-size: 1rem;
  line-height: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  flex: 0 0 100%;
  color: #d8dfe4; /* $grey */
  transition: top 200ms ease-in-out, font-size 200ms ease-in-out, line-height 200ms ease-in-out;
  cursor: text;
}
`